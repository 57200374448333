<template>
    <div class="row">
        <div class="col-lg-4 col-md-5">
            <b-card body-class="p-1">
                <div class="row">
                    <div class="col-12 mb-1">
                        <label class="form-label">Cari</label>
                        <v-select-list v-model="cari" multiple :options="cariList" label="CARI_ISIM" v-on:search="getCariList" placeholder="Cari" :loading="cariLoading"></v-select-list>
                    </div>
                    <div class="col-12 mb-1">
                        <label class="form-label">Son Cevap Tarihi</label>
                        <v-date-picker v-model="request.LastAnswerDate" placeholder="Son Cevap Tarihi"></v-date-picker>
                    </div>
                    <!-- <div class="col-12 mb-1">
                        <label class="form-label">Sözleşme Başlangıç Tarihi</label>
                        <v-date-picker v-model="request.AgreementStartDate" placeholder="Sözleşme Başlangıç Tarihi"></v-date-picker>
                    </div> -->
                    <div class="col-12 mb-1">
                        <label class="form-label">Sözleşme Bitiş Tarihi</label>
                        <v-date-picker v-model="request.AgreementEndDate" placeholder="Sözleşme Bitiş Tarihi"></v-date-picker>
                    </div>
                    <div class="col-12 mb-1">
                        <b-form-checkbox v-model="request.InflationAffectValueActive">Enflasyon Etki Oranı (%)</b-form-checkbox>
                        <input class="form-control mt-1" type="number" v-if="request.InflationAffectValueActive" placeholder="Enflasyon Etki Oranı (%)" v-model="request.InflationAffectValue" />
                    </div>
                    <div class="col-12 mb-1">
                        <b-form-checkbox v-model="request.FuelAffectValueActive">Yakıt Etki Oranı (%)</b-form-checkbox>
                        <input class="form-control mt-1" type="number" v-if="request.FuelAffectValueActive" placeholder="Yakıt Etki Oranı (%) " v-model="request.FuelAffectValue" />
                    </div>
                    <div class="col-12 mb-1">
                        <b-form-checkbox v-model="request.FuelAffectRateValueActive">Yakıt Fiyatı Artış/Azalış Oranı (%)</b-form-checkbox>
                        <input class="form-control mt-1" type="number" v-if="request.FuelAffectRateValueActive" placeholder="Yakıt Fiyatı Artış/Azalış Oranı (%)" v-model="request.FuelAffectRateValue" />
                    </div>
                    <div class="col-12 text-right">
                        <v-executer :loading="sending" :action="send" text="Gönder"></v-executer>
                    </div>
                </div>
            </b-card>
        </div>
        <div class="col-lg-8 col-md-7">
            
            <b-modal id="create-or-update" :title="editing ? 'Güncelle' : 'Oluştur'" no-close-on-esc no-close-on-backdrop>
                <offer-item-create-or-update v-model="offerItem"></offer-item-create-or-update>
                <template #modal-footer><v-executer :editing="editing" :action="createOrUpdate"></v-executer></template>
            </b-modal>


            <b-card no-body>
                <h3 class="px-1 pt-1">Teklif Rotaları</h3>
                <div class="col-12 text-left">
                    <div class="border rounded p-75">
                        <div class="row align-items-center">
                            <div class="col-12 mb-1">
                                <button class="btn btn-flat-success" v-on:click="downloadSample">
                                    <feather-icon icon="FileIcon"></feather-icon>
                                    Örnek Excel
                                </button>
                            </div>
                            <div class="col-12 mb-1">
                                <label class="form-label">Excel İle Rota Ekle</label> 
                                <v-file-uploader :accept="['xlsx']" v-model="ExcelFile" valueParameter="File" ></v-file-uploader>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <button :disabled="ExcelFile.File == null" class="btn btn-primary text-nowrap waves-effect d-block" v-on:click="fileUpload" >
                                    <feather-icon icon="PlusIcon" size="16" class="mr-1" ></feather-icon>Excel ile Rota Ekle
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(errors || []).length" class="border-danger rounded m-1 p-50">
                    <h4 class="font-weight-bolder text-danger">Hatalar</h4>
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th>Hata</th>
                                <th>Teklif Kalemleri</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(e,i) in errors" :key="i">
                                <td class="w-30 text-secondary font-weight-bolder">{{e.Error}}</td>
                                <td class="w-70">
                                    <ul class="list-group">
                                        <li class="list-group-item p-25" v-for="(oi,ii) in e.OfferItems" :key="ii">
                                            <table class="table table-sm">
                                                <tbody>
                                                    <tr>
                                                        <th class="w-30">Başlangıç Yeri</th>
                                                        <td class="w-70">{{oi.StartTransferRegionName || '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="w-30">Bitiş Yeri</th>
                                                        <td class="w-70">{{oi.EndTransferRegionName || '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="w-30">Stok</th>
                                                        <td class="w-70">{{oi.MaterialName || '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="w-30">Araç Tipi</th>
                                                        <td class="w-70">{{oi.VehicleTypeName || '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="w-30">Taşıma Durumu</th>
                                                        <td class="w-70">{{oi.VehicleTransportStatusName || '-'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-1 table-responsive" v-if="InvalidRoutes.length>0">
                    <div class="col-12">
                        <h3 class="font-weight-bolder">Geçersiz Bilgi İçeren Kayıtlar:</h3>
                        <table class="table table-sm table-bordered align-middle ">
                            <thead>
                                <tr>
                                    <th class="w-10 text-nowrap">Başlangıç Bölge Kodu</th>
                                    <th class="w-10 text-nowrap">Bitiş Bölge Kodu</th>
                                    <th class="w-10 text-nowrap">Araç Tipi Kodu</th>
                                    <th class="w-10 text-nowrap">Ürün Tipi Kodu</th>
                                    <th class="w-10 text-nowrap">Taşıma Tipi</th>
                                    <th class="w-40 text-nowrap">Hatalar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(data) in InvalidRoutes">
                                    <tr :key="data.Id">
                                        <td class="text-nowrap" v-if="data.BaslangicBolgeKodu != null">{{ data.BaslangicBolgeKodu }}</td>
                                        <td class="text-nowrap" v-if="data.BitisBolgeKodu != null">{{ data.BitisBolgeKodu }}</td>
                                        <td class="text-nowrap" v-if="data.AracTipiKodu != null">{{ data.AracTipiKodu }}</td>
                                        <td class="text-nowrap"  v-if="data.TasinanUrunStokKodu != null">{{data.TasinanUrunStokKodu}}</td>
                                        <td class="text-nowrap" v-if="data.TasimaDurumu != null">{{ data.TasimaDurumu }}</td>
                                        <td class="text-nowrap bg-light-danger p-50" v-if="data.Errors.length>0" >
                                            <ul class="list-group">
                                                <li class="list-group-item" v-for="(x,index) in data.Errors" :key="index">{{x}}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div class="row mb-2 table-responsive" v-if="NullValueRoutes.length>0">
                    <div class="col-12">
                        <h3 class="font-weight-bolder">Boş Değer İçeren Kayıtlar:</h3>
                        <table class="table table-sm table-bordered align-middle ">
                            <thead>
                                <tr>
                                    <th class="w-18 text-nowrap">Başlangıç Bölge Kodu</th>
                                    <th class="w-18 text-nowrap">Bitiş Bölge Kodu</th>
                                    <th class="w-18 text-nowrap">Araç Tipi Kodu</th>
                                    <th class="w-18 text-nowrap">Ürün Tipi Kodu</th>
                                    <th class="w-18 text-nowrap">Taşıma Tipi</th>
                                    <th class="w-10 text-nowrap">Fiyat</th>
                                </tr>
                            </thead>
                            <tbody class="">
                                <template v-for="(data) in NullValueRoutes">
                                    <tr :key="data.Id">
                                        <td v-if="data.BaslangicBolgeKodu != null">{{ data.BaslangicBolgeKodu }}</td>
                                        <td v-if="data.BaslangicBolgeKodu == null" class="text-nowrap bg-light-danger">DEĞER YOK</td>
                                        
                                        <td  v-if="data.BitisBolgeKodu != null">{{ data.BitisBolgeKodu }}</td>
                                        <td  v-if="data.BitisBolgeKodu == null" class="text-nowrap bg-light-danger">DEĞER YOK</td>

                                        <td v-if="data.AracTipiKodu != null">{{ data.AracTipiKodu }}</td>
                                        <td v-if="data.AracTipiKodu == null" class="text-nowrap bg-light-danger">DEĞER YOK</td>

                                        <td class="text-nowrap" v-if="data.TasinanUrunStokKodu != null">{{data.TasinanUrunStokKodu}}</td>
                                        <td class="text-nowrap bg-light-danger" v-if="data.TasinanUrunStokKodu == null">DEĞER YOK</td>

                                        <td v-if="data.TasimaDurumu != null">{{ data.TasimaDurumu }}</td>
                                        <td v-if="data.TasimaDurumu == null" class="text-nowrap bg-light-danger">DEĞER YOK</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 p-2 mb-2">
                        <label class="form-label">Teklif Şartnamesi</label> 
                        <v-file-uploader :accept="['pdf']" v-model="request" valueParameter="File" ></v-file-uploader>
                    </div>
                </div>
                <v-table    ref="table"
                            with-items
                            :settings="false"
                            :add-callback="createNew"
                            :items="request.OfferItems"
                            :fields="fields"
                            :buttons="buttons"></v-table>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BFormCheckbox } from 'bootstrap-vue'
import { NetsisApi } from '@/api/netsis'
import { OfferApi } from '@/api/offer'
import OfferItemCreateOrUpdate from './components/OfferItemCreateOrUpdate.vue'
import randomatic from 'randomatic'
import VFileUploader from '@/components/v-file-uploader/VFileUploader.vue'
import { serialize } from 'object-to-formdata'
import Additional from '@/utilities/additional'
export default {
    components:{
        VFileUploader,
        BCard,
        BFormCheckbox,
        OfferItemCreateOrUpdate
    },
    data(){
        return {
            ExcelFile:{
                File:null
            },
            cariList:[],
            cariLoading:false,
            request:{
                CariInformations:[],
                LastAnswerDate:null,
                AgreementStartDate:null,
                AgreementEndDate:null,
                OfferItems:[],
                InflationAffectValueActive:false,
                InflationAffectValue:null,
                FuelAffectValueActive:false,
                FuelAffectValue:null,
                FuelAffectRateValueActive:false,
                FuelAffectRateValue:null,
                File:null
            },
            offerItem:{
                DummyCode:null,
                StartTransferRegionId:null,
                StartTransferRegionName:null,
                EndTransferRegionId:null,
                EndTransferRegionName:null,
                MaterialId:null,
                MaterialName:null,
                VehicleTypeId:null,
                VehicleTypeName:null,
                VehicleTransportStatus:null,
                VehicleTransportStatusName:null,
            },
            editing:false,
            fields:[
                {name:'Başlangıç Yeri',key:'StartTransferRegionName',thClass:'w-15'},
                {name:'Bitiş Yeri',key:'EndTransferRegionName',thClass:'w-15'},
                {name:'Stok',key:'MaterialName',thClass:'w-40'},
                {name:'Araç Tipi',key:'VehicleTypeName',thClass:'w-15'},
                {name:'Taşıma Durumu',key:'VehicleTransportStatusName',thClass:'w-15'},
            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"EditIcon",variant:"primary",action:this.edit},
                { text:"Sil",key:"delete",icon:"TrashIcon",variant:"danger",action:this.delete },
            ],
            sending:false,
            errors:[],
            InvalidRoutes:[],
            NullValueRoutes:[],
        }
    },
    methods:{
        async fileUpload(){
            this.request.OfferItems=[]
            this.NullValueRoutes=[]
            this.InvalidRoutes=[]
            let data = await Additional.ToFormData(this.ExcelFile)
            OfferApi.UploadExcel(data).then(res => {
                this.$result(res)
                if(res.data.Success){
                    this.request.OfferItems.push(...res.data.Data.ValidRoutes)
                    
                    
                }else{
                    this.request.OfferItems.push(...res.data.Data.ValidRoutes)
                    this.InvalidRoutes.push(...res.data.Data.InvalidRoutes)
                    this.NullValueRoutes.push(...res.data.Data.HasNullValueRoutes)
                }
            }).catch(err=> {
                console.err(err)
            })
        },
        clearOfferItemForm(){
            this.editing = false
            this.offerItem.DummyCode = null
            this.offerItem.StartTransferRegionId = null
            this.offerItem.StartTransferRegionName = null
            this.offerItem.EndTransferRegionId = null
            this.offerItem.EndTransferRegionName = null
            this.offerItem.MaterialId = null
            this.offerItem.MaterialName = null
            this.offerItem.VehicleTypeId = null
            this.offerItem.VehicleTypeName = null
            this.offerItem.VehicleTransportStatus = null
            this.offerItem.VehicleTransportStatusName = null
        },
        getCariList(searchKey){
            if(searchKey){
                this.cariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    const currentCaris = this.cari
                    this.cariList = res.data.Data
                    currentCaris?.filter(c=>!this.cariList.map(cc=>cc.CARI_KOD).includes(c.CARI_KOD)).forEach(c=>this.cariList.push(c))
                    this.cariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.cariLoading = false
                })
            }
        },
        createNew(){
            this.clearOfferItemForm()
            this.$showModal('create-or-update')
        },
        edit(item){
            this.editing = true
            this.offerItem.DummyCode = item.DummyCode
            this.offerItem.StartTransferRegionId = item.StartTransferRegionId
            this.offerItem.StartTransferRegionName = item.StartTransferRegionName
            this.offerItem.EndTransferRegionId = item.EndTransferRegionId
            this.offerItem.EndTransferRegionName = item.EndTransferRegionName
            this.offerItem.MaterialId = item.MaterialId
            this.offerItem.MaterialName = item.MaterialName
            this.offerItem.VehicleTypeId = item.VehicleTypeId
            this.offerItem.VehicleTypeName = item.VehicleTypeName
            this.offerItem.VehicleTransportStatus = item.VehicleTransportStatus
            this.offerItem.VehicleTransportStatusName = item.VehicleTransportStatusName
            this.$showModal('create-or-update')
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem teklif rotasını silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    this.$delete(this.request.OfferItems,this.request.OfferItems.findIndex(oi=> oi.DummyCode == item.DummyCode))
                }
            })
        },
        downloadSample(){
            OfferApi.DownloadSampleFile().then(res=>{
                Additional.DownloadFile(res.data, 'Teklif Rota Şablonu.xlsx')
            })
        },
        createOrUpdate(){
            if(this.offerItem.DummyCode == null){
                this.offerItem.DummyCode = randomatic('A0',10)
                this.request.OfferItems.push(structuredClone(this.offerItem))
            }else{
                let item = this.request.OfferItems.find(oi=>oi.DummyCode == this.offerItem.DummyCode)
                this.$set(item,'StartTransferRegionId',this.offerItem.StartTransferRegionId)
                this.$set(item,'StartTransferRegionName',this.offerItem.StartTransferRegionName)
                this.$set(item,'EndTransferRegionId',this.offerItem.EndTransferRegionId)
                this.$set(item,'EndTransferRegionName',this.offerItem.EndTransferRegionName)
                this.$set(item,'MaterialId',this.offerItem.MaterialId)
                this.$set(item,'MaterialName',this.offerItem.MaterialName)
                this.$set(item,'VehicleTypeId',this.offerItem.VehicleTypeId)
                this.$set(item,'VehicleTypeName',this.offerItem.VehicleTypeName)
                this.$set(item,'VehicleTransportStatus',this.offerItem.VehicleTransportStatus)
                this.$set(item,'VehicleTransportStatusName',this.offerItem.VehicleTransportStatusName)
            }
            this.$hideModal('create-or-update')
        },
        send(){
            this.sending = true
            const x = serialize(this.request,{indices: true,dotsForObjectNotation: true,nullsAsUndefineds:true})
            OfferApi.Create(x).then(res=>{
                this.sending = false
                if(res.data.Success){
                    this.$router.push({name:'offer-request'})
                }else{
                    if(res.data.Data != null || (Array.isArray(res.data.Data) && (res.data.Data || []).length > 0)){
                        this.errors = res.data.Data
                    }
                }
                this.$result(res)
            }).catch(err=>{
                this.sending = false
                this.$result(null,err)
            })
        }
    },
    computed:{
        cari:{
            get:function(){
                return this.cariList.filter(s=>this.request.CariInformations.map(c=>c.CariCode).includes(s.CARI_KOD))
            },
            set:function(value){
                this.request.CariInformations = value.map(c=>({CariCode:c.CARI_KOD,CariName:c.CARI_ISIM_ONLY}))
            }
        },
    }
}
</script>

<style>

</style>