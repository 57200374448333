<template>
        <div class="row">
            <div class="col-md-12 mb-1">
                <label class="form-label">Başlangıç Bölgesi</label>
                <v-select-list v-model="startRegion" placeholder="Başlangıç Bölgesi" label="RegionName" :options="startRegions"/>   
            </div>
            <div class="col-md-12 mb-1">
                <label class="form-label">Bitiş Bölgesi</label>
                <v-select-list v-model="endRegion" placeholder="Bitiş Bölgesi" label="RegionName" :options="endRegions"/>   
            </div>
            <div class="col-md-12 mb-1">
                <label class="form-label">Taşıma Tipi</label>
                <v-select-list placeholder="Taşıma Tipi" v-model="transportStatus" label="Name" :options="vehicleTransportStatuses"/>
            </div>
            <div class="col-md-12 mb-1">
                <label class="form-label">Stok</label>
                <v-select-list v-model="stok" :options="stoks" label="Name" placeholder="Stok" :loading="stokLoading"></v-select-list>
            </div>
            <div class="col-md-12">
                <label class="form-label">Araç Tipi</label>
                <v-select-list :options="vehicleTypes" v-model="vehicleType" placeholder="Araç Tipi" label="Name"></v-select-list>
            </div>
        </div>
</template>

<script>
import { SystemDataApi } from '@/api/system-data'
import {VehicleTypeApi} from '@/api/vehicle-type'
import {TransferRegionApi} from '@/api/transfer-region'
import {MaterialApi} from '@/api/material'
export default {
    props:{
        value:Object
    },
    data(){
        return{
            startRegions:[],
            endRegions:[],
            stoks:[],
            vehicleTransportStatuses:[],
            vehicleTypes:[],
            stokLoading:null
            
        }
    },
    mounted(){
        this.getRegions()
        this.getVehicleTransportStatuses()
        this.getVehicleTypes()
        this.getStoks()
    },
    watch:{
    },
    methods:{
        getVehicleTypes(){
            VehicleTypeApi.List().then(res => {
                this.vehicleTypes = res.data.Data
            }).catch(err=> {
                console.error(err)
            })
        },
        getRegions(){
            TransferRegionApi.List().then(res=>{
                this.startRegions = res.data.Data
                this.endRegions = res.data.Data
            })
        },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
        getVehicleTransportStatuses(){
            SystemDataApi.VehicleTransportStatuses().then(res=> {
                this.vehicleTransportStatuses = res.data.Data
            })
        }
    },
    computed:{
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Id==this.value.MaterialId)
            },
            set:function(value){
                this.value.MaterialId = value?.Id || null
                this.value.MaterialName = value?.Name || null
            }
        },
        vehicleType:{
            get:function(){
                return this.vehicleTypes.find(s=>s.Id==this.value.VehicleTypeId)
            },
            set:function(value){
                this.value.VehicleTypeName = value?.Name || null
                this.value.VehicleTypeId = value?.Id || null
            }
        },
        transportStatus:{
            get:function(){
                return this.vehicleTransportStatuses.find(b=>b.Value==this.value.VehicleTransportStatus)
            },
            set:function(value){
                this.value.VehicleTransportStatusName = value.Name
                this.value.VehicleTransportStatus = value.Value 
            }
        },
        startRegion:{
            get:function(){
                return this.startRegions.find(b=>b.Id==this.value.StartTransferRegionId)
            },
            set:function(value){
                this.value.StartTransferRegionName =value?.RegionName ||null
                this.value.StartTransferRegionId = value?.Id || null
            }
        },
        endRegion:{
            get:function(){
                return this.endRegions.find(b=>b.Id==this.value.EndTransferRegionId)
            },
            set:function(value){
                this.value.EndTransferRegionName =value?.RegionName ||null
                this.value.EndTransferRegionId = value?.Id || null
            }
        }
    }
}
</script>

<style>

</style>